//Assisted Application
export enum AssistedApplicationErrorIds {
  //Pricing
  ADOMissingRequiredInputs = 'Pricing:ADOMissingRequiredInputs',

  //Identity Verification
  IdentityVerificationInvalidRequestFirstNamePattern = 'IdentityVerification:InvalidRequest:FirstName:Pattern',
  IdentityVerificationInvalidRequestFirstNameMax = 'IdentityVerification:InvalidRequest:FirstName:Max',
  IdentityVerificationInvalidRequestFirstNameEmpty = 'IdentityVerification:InvalidRequest:FirstName:Empty',
  IdentityVerificationInvalidRequestMiddleNamePattern = 'IdentityVerification:InvalidRequest:MiddleName:Pattern',
  IdentityVerificationInvalidRequestMiddleNameMax = 'IdentityVerification:InvalidRequest:MiddleName:Max',
  IdentityVerificationInvalidRequestLastNamePattern = 'IdentityVerification:InvalidRequest:LastName:Pattern',
  IdentityVerificationInvalidRequestLastNameMax = 'IdentityVerification:InvalidRequest:LastName:Max',
  IdentityVerificationInvalidRequestLastNameEmpty = 'IdentityVerification:InvalidRequest:LastName:Empty',
  IdentityVerificationInvalidRequestDOBYearMin = 'IdentityVerification:InvalidRequest:DOB:Year:Min',
  IdentityVerificationInvalidRequestDOBYearEmpty = 'IdentityVerification:InvalidRequest:DOB:Year:Empty',
  IdentityVerificationInvalidRequestDOBMonthMin = 'IdentityVerification:InvalidRequest:DOB:Month:Min',
  IdentityVerificationInvalidRequestDOBMonthMax = 'IdentityVerification:InvalidRequest:DOB:Month:Max',
  IdentityVerificationInvalidRequestDOBMonthEmpty = 'IdentityVerification:InvalidRequest:DOB:Month:Empty',
  IdentityVerificationInvalidRequestDOBDayMin = 'IdentityVerification:InvalidRequest:DOB:Day:Min',
  IdentityVerificationInvalidRequestDOBDayMax = 'IdentityVerification:InvalidRequest:DOB:Day:Max',
  IdentityVerificationInvalidRequestDOBDayEmpty = 'IdentityVerification:InvalidRequest:DOB:Day:Empty',
  IdentityVerificationInvalidRequestTelephoneClassification = 'IdentityVerification:InvalidRequest:Telephone:Classification',
  IdentityVerificationInvalidRequestTelephoneAreaCodePattern = 'IdentityVerification:InvalidRequest:Telephone:AreaCode:Pattern',
  IdentityVerificationInvalidRequestTelephoneAreaCodeMax = 'IdentityVerification:InvalidRequest:Telephone:AreaCode:Max',
  IdentityVerificationInvalidRequestTelephoneAreaCodeEmpty = 'IdentityVerification:InvalidRequest:Telephone:AreaCode:Empty',
  IdentityVerificationInvalidRequestTelephoneExchangePattern = 'IdentityVerification:InvalidRequest:Telephone:Exchange:Pattern',
  IdentityVerificationInvalidRequestTelephoneExchangeMax = 'IdentityVerification:InvalidRequest:Telephone:Exchange:Max',
  IdentityVerificationInvalidRequestTelephoneExchangeEmpty = 'IdentityVerification:InvalidRequest:Telephone:Exchange:Empty',
  IdentityVerificationInvalidRequestTelephoneNumberPattern = 'IdentityVerification:InvalidRequest:Telephone:Number:Pattern',
  IdentityVerificationInvalidRequestTelephoneNumberMax = 'IdentityVerification:InvalidRequest:Telephone:Number:Max',
  IdentityVerificationInvalidRequestTelephoneNumberEmpty = 'IdentityVerification:InvalidRequest:Telephone:Number:Empty',
  IdentityVerificationInvalidRequestTelephoneExtensionPattern = 'IdentityVerification:InvalidRequest:Telephone:Extension:Pattern',
  IdentityVerificationInvalidRequestTelephoneExtensionMax = 'IdentityVerification:InvalidRequest:Telephone:Extension:Max',
  IdentityVerificationInvalidRequestCurrentAddressAddressIdPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:AddressId:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressStreetNumberPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetNumber:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressStreetNumberMax = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetNumber:Max',
  IdentityVerificationInvalidRequestCurrentAddressStreetNamePattern = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetName:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressStreetNameMax = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetName:Max',
  IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Max = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetAddress1:Max',
  IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Empty = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetAddress1:Empty',
  IdentityVerificationInvalidRequestCurrentAddressStreetAddress2Max = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetAddress2:Max',
  IdentityVerificationInvalidRequestCurrentAddressCityMax = 'IdentityVerification:InvalidRequest:CurrentAddress:City:Max',
  IdentityVerificationInvalidRequestCurrentAddressCityEmpty = 'IdentityVerification:InvalidRequest:CurrentAddress:City:Empty',
  IdentityVerificationInvalidRequestCurrentAddressStateMax = 'IdentityVerification:InvalidRequest:CurrentAddress:State:Max',
  IdentityVerificationInvalidRequestCurrentAddressStateEmpty = 'IdentityVerification:InvalidRequest:CurrentAddress:State:Empty',
  IdentityVerificationInvalidRequestCurrentAddressZip5Max = 'IdentityVerification:InvalidRequest:CurrentAddress:Zip5:Max',
  IdentityVerificationInvalidRequestCurrentAddressZip5Empty = 'IdentityVerification:InvalidRequest:CurrentAddress:Zip5:Empty',
  IdentityVerificationInvalidRequestCurrentAddressCountyPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:County:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressCountyMax = 'IdentityVerification:InvalidRequest:CurrentAddress:County:Max',
  IdentityVerificationInvalidRequestCurrentAddressCountryMax = 'IdentityVerification:InvalidRequest:CurrentAddress:Country:Max',
  IdentityVerificationInvalidRequestCurrentAddressUnitNumberPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:UnitNumber:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressUnitNumberMax = 'IdentityVerification:InvalidRequest:CurrentAddress:UnitNumber:Max',
  IdentityVerificationInvalidRequestCurrentAddressPostalCodeMax = 'IdentityVerification:InvalidRequest:CurrentAddress:PostalCode:Max',
  IdentityVerificationServerTimout = 'IdentityVerification:ServerTimeout',
  IdentityVerificationAddressError = 'IdentityVerification:AddressError',
  IdentityVerificationProcessingError = 'IdentityVerification:ProcessingError',
  IdentityVerificationApplicationNotFound = 'IdentityVerification:ApplicationNotFound',
}

export enum BackOfficeErrorIds {
  ApplicationSubmissionResetMissingApplicationID = 'ApplicationSubmissionReset:MissingApplicationID',
  ApplicationSubmissionResetInvalidApplicationID = 'ApplicationSubmissionReset:InvalidApplicationID',
  ApplicationSubmissionResetApplicationNotFound = 'ApplicationSubmissionReset:ApplicationNotFound',
  ApplicationSubmissionResetApplicationSubmitted = 'ApplicationSubmissionReset:ApplicationSubmitted',
  ApplicationSubmissionResetNoCompletedESignCeremony = 'ApplicationSubmissionReset:NoCompletedESignCeremony',
}

export enum ApiErrorIds {
  QuestionnaireVersionMissingEntityMappingsError = 'QuestionnaireVersionMissingEntityMappingsError',
  QuestionnaireVersionEntityMappingsParsingError = 'QuestionnaireVersionEntityMappingsParsingError',
}
