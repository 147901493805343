import { z } from 'zod';

import { ApiErrorIds } from '../errorIds';

export function makeCustomAxiosErrorSchema<DataType extends z.ZodTypeAny>(data: DataType) {
  return z.object({
    response: z.object({
      data: z.object({
        errors: z.object({
          errorId: z.nativeEnum(ApiErrorIds),
          data: data.optional(),
        }),
      }),
    }),
  });
}

export const customAxiosErrorWithUnknownDataSchema = makeCustomAxiosErrorSchema(z.unknown());
